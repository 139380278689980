import {
  BaseStepIdEnum,
  FieldEntityTypeEnum,
  FieldPropertyTypeEnum,
  FieldTypeEnum,
  StepConfig,
  StepTypeEnum,
} from 'shared-domain';

export const stepsConfig: StepConfig[] = [
  {
    id: BaseStepIdEnum.company_search,
  },
  {
    id: BaseStepIdEnum.company_list,
  },
  {
    id: BaseStepIdEnum.company_edit,
  },
  {
    id: 'additional_data',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'self_employed',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          hasHelper: true,
          options: ['true', 'false'],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'transaction_volume_in_euros_per_year',
          type: FieldTypeEnum.select,
          isRequired: true,
          isEnabled: true,
          options: [
            'less_than_10_000',
            'between_10_000_and_50_000',
            'between_50_000_and_250_000',
            'more_than_250_000',
          ],
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'number_of_transactions_per_year',
          type: FieldTypeEnum.select,
          isRequired: true,
          isEnabled: true,
          options: [
            'less_than_10_000',
            'between_10_000_and_50_000',
            'between_50_000_and_100_000',
            'between_100_000_and_500_000',
            'more_than_500_000',
          ],
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'fund_origin',
          type: FieldTypeEnum.select,
          isMulti: true,
          isRequired: true,
          isEnabled: true,
          options: [
            'origins_funds_operating_operations',
            'origins_funds_external_investors',
            'origins_funds_debt',
            'origins_funds_shareholders_contribution',
            'origins_funds_subsidies',
            'origins_funds_online_sales',
            'origins_funds_partnerships',
            'origins_funds_passive_income',
          ],
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.custom,
        },
      ],
    },
  },
  {
    id: BaseStepIdEnum.individuals_list,
    config: { hasApplicant: true },
  },
];
